import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  @Input() message;

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  redirectToBox() {
    this.activeModal.close();
    this.router.navigateByUrl('/donation/box');
  }

}
