import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Constant } from '../settings/constants';

@Injectable()
export class AuthenticationService {

  loginApi: string = Constant.API_ENDPOINT + 'login';
  checkLoginApi: string = Constant.API_ENDPOINT + 'checkLogin';

  token: string;
  isLoggedIn: boolean = false;
  user_type: string = null;
  user_name: string = null;
  user_img: string = null;

  constructor(private httpClient: HttpClient, private router: Router) {
    // set token if saved in local storage
    var currentUser = JSON.parse(localStorage.getItem('unhead_auth_token_user'));
    var user_type = JSON.parse(localStorage.getItem('user_type'));
    var user_name = localStorage.getItem('user_name');
    var user_img = localStorage.getItem('user_img');
    this.token = currentUser && currentUser.token;
    if (this.token && user_type) {
      this.isLoggedIn = true;
      this.user_type = user_type;
      this.user_name = user_name;
      this.user_img = user_img;
    } else {
      this.isLoggedIn = false;
      this.user_type = null;
      this.user_name = null;
      this.user_img = null;
    }
  }
} 