import { environment } from '../../environments/environment';
export class Constant {

	public static API_ENDPOINT: string = environment.api_endpoint;
	public static GOOGLE_PROVIDER_ID: string = environment.google_provider_id;
	public static FACEBOOK_PROVIDER_ID: string = environment.facebook_provider_id;
	public static FB_APP_KEY: string = "757973637744555";
	public static STRIPE_PUBLISHABLE_KEY: string = environment.stripe_publishable_key;
	public static ENDPOINT: object = {
		'getAllBanners': 'admin/banner/allbanner',
		'adminBannerList': 'admin/banner/listing',
		'adminBannerAdd': 'admin/banner/add',
		'adminBannerEdit': 'admin/banner/edit',
		'adminBannerDetails': 'admin/banner/details',
		'adminBannerDelete': 'admin/banner/delete',
		'adminBannerImageAddUpdate': 'admin/banner/store-image',
		'adminCategoryList': 'admin/category/listing',
		'adminCampaignList': 'admin/campaign/listing',
		'adminCategoryAdd': 'admin/category/add',
		'adminCategoryEdit': 'admin/category/edit',
		'adminCategoryDetails': 'admin/category/details',
		'adminCategoryDelete': 'admin/category/delete',
		'adminCharityList': 'admin/charity/listing',
		'adminCharityAdd': 'admin/charity/add',
		'adminCharityEdit': 'admin/charity/edit',
		'adminCharityDetails': 'admin/charity/details',
		'adminCharityDelete': 'admin/charity/delete',
		'adminProjectList': 'admin/project/listing',
		'adminProjectAdd': 'admin/project/add',
		'adminProjectEdit': 'admin/project/edit',
		'adminProjectDetails': 'admin/project/details',
		'adminProjectDelete': 'admin/project/delete',
		'adminNewsList': 'admin/news/listing',
		'adminNewsAdd': 'admin/news/add',
		'adminNewsEdit': 'admin/news/edit',
		'adminNewsDetails': 'admin/news/details',
		'adminNewsDelete': 'admin/news/delete',
		'adminBlogCategoryList': 'admin/blog-category/listing',
		'adminBlogCategoryAdd': 'admin/blog-category/add',
		'adminBlogCategoryEdit': 'admin/blog-category/edit',
		'adminBlogCategoryDetails': 'admin/blog-category/details',
		'adminBlogCategoryDelete': 'admin/blog-category/delete',
		'adminBlogPostList': 'admin/blog-post/listing',
		'adminBlogPostAdd': 'admin/blog-post/add',
		'adminBlogPostEdit': 'admin/blog-post/edit',
		'adminBlogPostDetails': 'admin/blog-post/details',
		'adminBlogPostDelete': 'admin/blog-post/delete',
		'adminCmsList': 'admin/cms',
		'adminCmsAdd': 'admin/cms/add',
		'adminCmsEdit': 'admin/cms/edit',
		'adminCmsDetails': 'admin/cms/details',
		'adminCmsDelete': 'admin/cms/delete',
		'adminDonorUserList': 'admin/donor-user/listing',
		'adminDonorUserChangeStatus': 'admin/donor-user/changestatus',
		'adminAdminUserList': 'admin/admin-user/listing',
		'adminAdminUserAdd': 'admin/admin-user/add',
		'adminAdminUserEdit': 'admin/admin-user/edit',
		'adminAdminUserDelete': 'admin/admin-user/delete',
		'adminAdminUserDetails': 'admin/admin-user/details',
		'adminSettingsList': 'admin/settings',
		'adminSettingsAdd': 'admin/settings/add',
		'adminSettingsEdit': 'admin/settings/edit',
		'adminSettingsDetails': 'admin/settings/details',
		'adminSettingsDelete': 'admin/settings/delete',
		'adminDonationList': 'admin/donation-list',
		'adminDonationCsv': 'admin/download-donation-csv',
		'adminDonationDetails': 'admin/donation-details',
		'adminReports': 'admin/reports',
		'adminReportsDonationDetails': 'admin/reports/donationDetails',
		'imageURL': 'local/public/images/',
		'signup': 'signup',
		'activate': 'signup-activate',
		'socialSignup': 'socialsignup',
		'socialLogin': 'sociallogin',
		'checkLogin': 'checkLogin',
		'login': 'login',
		'checkSocialEmail': 'checksocialemail',
		'preferences': 'preferences',
		'update-charity': 'update-charity',
		'charity-profile': 'charity-profile',
		'delete-charity-profile-image': 'delete-charity-profile-image',
		'project-list': 'project-list',
		'getAllProjects': 'get-all-projects',
		'getAllCharities': 'get-all-charities',
		'subscriber': 'add-subscriber',
		'start-project': 'start-project',
		'hold-project': 'hold-project',
		'delete-project': 'delete-project',
		'get-project': 'get-project',
		'store-project': 'store-project',
		'search-project': 'search-project',
		'categories': 'categories',
		'active-categories': 'active-categories',
		'country': 'country',
		'state': 'state',
		'city': 'city',
		'signup-charity': 'signup-charity',
		'change-password': 'change-password',
		'reset-password': 'reset-password',
		'logout': 'logout',
		'password-reset': 'password-reset',
		'charity-detail': 'charity-detail',
		'cmsPage': 'get-cms-page',
		'getInTouch': 'get-in-touch',
		'support': 'support',
		'project-detail': 'project-detail',
		'getAlias': 'is-alias-exist',
		'user-profile': 'user-profile',
		'update-user': 'update-user',
		'update-user-settings': 'update-user-settings',
		'donor-dashboard': 'donor-dashboard',
		'project-donation-history': 'project-donation-history',
		'charity-donation-history': 'charity-donation-history',
		'ccpWithoutLogin': 'ccp-without-login',
		'ccpWithLogin': 'ccp-with-login',
		'getAllBlogPosts': 'get-all-blogs',
		'blogDetails': 'blog-details',
		'getAllNewsPosts': 'get-all-news',
		'newsDetails': 'news-details',
		'csv-project-donation': 'csv-project-donation',
		'csv-charity-donation': 'csv-charity-donation',
		'charity-dashboard': 'charity-dashboard',
		'getStripeAuthCode': 'get-authorization-code',
		'admin-dashboard': 'admin/admin-dashboard',
		'add-user-device-info': 'add-user-device-info',
		'get-charity-by-number': 'get-charity-by-number',
		'user-charity-donation-history': 'user-charity-donation-history',
		'user-project-donation-history': 'user-project-donation-history',
		'csv-user-project-donation': 'csv-user-project-donation',
		'csv-user-charity-donation': 'csv-user-charity-donation',
		'signup-charity-api-search': 'signup-charity-api-search',
		'banner-charity-api-search': 'banner-charity-api-search',
		'is-charity-registered': 'is-charity-registered',
		'paymentDetail': 'payment-detail',
		'header-search': 'header-search',
		'directPayment': 'direct-payment',
		'addItem': 'add-item',
		'getItems': 'get-items',
		'removeItem': 'remove-item',
		'addItemByUser': 'add-item-by-user',
		'getItemsByUser': 'get-items-by-user',
		'removeItemByUser': 'remove-item-by-user',
		'remove-donation-history-by-user': 'remove-donation-history-by-user',
		'remove-donation-history-by-charity': 'remove-donation-history-by-charity',
		'social-user': 'social-user',
		'getFundRaisings': 'getFundRaisings',
		'getFundDetails': 'getFundDetails',
		'getFundDetailsAuth': 'getFundDetailsAuth',
		'deleteFundRaising': 'deleteFundRaising',
		'saveFundUpdate': 'fund-raising/saveFundUpdate',
		'getFundUpdates': 'fund-raising/getFundUpdates',
		'getCharities': 'fund-raising/getCharities',
		'saveOfflineDonation': 'fund-raising/saveOfflineDonation',
		'saveFundRaisingImpact': 'fund-raising/saveFundRaisingImpact',
		'saveCampaign': 'fund-raising/saveCampaign',
		'getFundImpacts': 'fund-raising/getFundImpacts',
		'getImpactDetails': 'fund-raising/getImpactDetails',
		'softDeleteImpact': 'fund-raising/softDeleteImpact',
		'getAllFundRaisings': 'get-all-FundRaisings',
		'checkURLAvailablity': 'campaign/checkURLAvailablity',
		'getAllFundCharities': 'campaign/getAllFundCharities',
		'getFundSupporters': 'campaign/getFundSupporters',
	}
	public static CryptoJS_ENCRYPT_DECRYPT_KEY = "123";
}