// Angular js core
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Third party
import { FacebookModule } from 'ngx-facebook';
import { FlashMessagesModule } from 'angular2-flash-messages';

// Routing and main component
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


// Directive
import { validateEmail } from './directives/email-validator.directive';

// Services
import { LoaderService } from './services/loader.service';
//import { MetaServiceService } from './services/meta-service.service';

//Code for social login - S
import { SocialLoginModule } from 'angularx-social-login';
import { AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';

import { HttpClientModule } from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { Constant } from './settings/constants';
import { AuthenticationService } from './services/authenticationFront.service';

const config = new AuthServiceConfig([
	{
		id: GoogleLoginProvider.PROVIDER_ID,
		provider: new GoogleLoginProvider(Constant.GOOGLE_PROVIDER_ID)
		//provider: new GoogleLoginProvider('624796833023-clhjgupm0pu6vgga7k5i5bsfp6qp6egh.apps.googleusercontent.com')
	},
	{
		id: FacebookLoginProvider.PROVIDER_ID,
		provider: new FacebookLoginProvider(Constant.FACEBOOK_PROVIDER_ID)
	}/* ,
	{
	  id: LinkedInLoginProvider.PROVIDER_ID,
	  provider: new LinkedInLoginProvider('78iqy5cu2e1fgr')
	} */
]);

export function provideConfig() {
	return config;
}

//Code for social login end

//import { OwlModule } from 'ngx-owl-carousel';

@NgModule({
	declarations: [
		AppComponent,
		validateEmail
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		NgHttpLoaderModule,
		AppRoutingModule,
		FlashMessagesModule,
		FacebookModule.forRoot(),
		SocialLoginModule,
		DeviceDetectorModule.forRoot(),
		BrowserAnimationsModule
	],
	providers: [
		LoaderService,
		AuthenticationService,
		/*MetaServiceService*/
		/* Code for Social Login - S */
		{
			provide: AuthServiceConfig,
			useFactory: provideConfig
		}
		/* Code for Social Login end */
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
