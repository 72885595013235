import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//import { FundSaveComponent } from '/user/fund-save/FundSaveComponent';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule',
  },
  {
    path: 'blog',
    loadChildren: './blog/blog.module#BlogModule',
  },
  {
    path: 'news',
    loadChildren: './news/news.module#NewsModule',
  },
  {
    path: '',
    loadChildren: './home/home.module#HomeModule',
  },

  {
    path: 'user',
    loadChildren: './user/user.module#UserModule',
  },
  {
    path: 'project',
    loadChildren: './project/project.module#ProjectModule',
  },
  {
    path: 'charity',
    loadChildren: './charity/charity.module#CharityModule',
  },
  {
    path: 'donation',
    loadChildren: './donation/donation.module#DonationModule',
  },
  {
    path: 'campaign',
    loadChildren: './campaign/campaign.module#CampaignModule',
  },
  {
    path: 'campaign/:fundId', loadChildren: './user/fund-details/fund-details.module#FundDetailsModule',
  },
];

@NgModule({
  //imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }