import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupComponent } from '../popup/popup.component';
import * as $ from 'jquery';
import { GeneralService } from './general.service';
import { AuthenticationService } from './authenticationFront.service';
import { Constant } from '../settings/constants';
//import { AppComponent } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class DonateService {

  constructor(
    private router: Router,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private service: GeneralService,
    public auth: AuthenticationService,
    //private app: AppComponent
  ) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  }

  /**
   * Get Items to box
   * @param Stirng key Item key name
   */
  getBoxItems(key: string): Array<any> {
    let items = [];
    // Parse json string
    items = JSON.parse(localStorage.getItem(key)) || [];
    return items;
  }

  /**
   * Added extra field into object
   * @param data 
   * @param key 
   * @param flag 
   * @param impact$ 
   */
  prepairItem(data: any, flag: any, impact$?: any) {
    // Push data into items object
    data['is_project'] = flag;
    if(data.is_campaign == 1){
      data['is_campaign'] = 1;
      data['is_project'] = 0;
      data['campaign_id'] = data.id;
      if(data.is_impact == 1){
        data['is_impact'] = 1;                
        data['project_amount'] = data.project_amount;
      }
    }
    if (typeof impact$ != 'undefined') {
      data['kid'] = data.id + '_' + impact$.id + '_' + flag;
      data['project_impact_id'] = impact$.id;
      if (impact$.title) {
        data['name'] = data['name'] + ' - ' + impact$.title;
      }
      data['project_amount'] = impact$.amount;
    } else {
      data['kid'] = data.id + '_' + flag;
    }
    var currentDate = new Date();
    data['added_time'] =  currentDate.getTime();
    return data;
  }

  /**
   * Add item into box like cart
   * @param Object data 
   * @param Stirng key Item key name
   */
  addItemToBox(data: any, key: string) {
    let items = [];

    items = JSON.parse(localStorage.getItem(key)) || [];
    items.push(data);
    // Set new value
    localStorage.setItem(key, JSON.stringify(items));
  }


  /**
   * Checck item has exist or not
   * @param Object data 
   * @param Stirng key Item key name
   */
  isItemExist(data: any, key: string, flag: any, impact$?: any): boolean {
    let items = [];
    let isExisting = false;
    items = JSON.parse(localStorage.getItem(key)) || [];
    // Check object has minimum 1 item
    if (items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        if (typeof impact$ != 'undefined') {
          if (items[i].project_impact_id === impact$.id) {
            isExisting = true;
            break;
          }
        } else {
          if (items[i].id === data.id && items[i]['is_project'] == flag) {
            if (typeof items[i].project_impact_id == 'undefined') {
              isExisting = true;
              break;
            }
          }
        }

      }
    }
    return isExisting;
  }
  /**
   * Remove item from box
   * @param Object data 
   * @param Stirng key Item key name
   */
  removeItemFromBox(id: any, key: string, flag: any) {
    let items = [];
    items = JSON.parse(localStorage.getItem(key)) || [];
    // Check object has minimum 1 item
    if (items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === id && items[i]['is_project'] == flag) {
          console.log('condition', flag)
          items.splice(i, 1);
          break;
        }
      }
    }
    // ReAdding the items after remove
    localStorage.setItem(key, JSON.stringify(items));
    this.setCartCount();
  }

  /**
   * Remove project impact item
   * @param id 
   * @param key 
   */
  removeImpactItem(id: any, key: string) {
    let items = [];
    items = JSON.parse(localStorage.getItem(key)) || [];
    // Check object has minimum 1 item
    if (items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].project_impact_id === id) {
          items.splice(i, 1);
          break;
        }
      }
    }
    // ReAdding the items after remove
    localStorage.setItem(key, JSON.stringify(items));
    this.setCartCount();
  }

  /**
   * Remove item form localStorage
   * @param key 
   */
  removeItemFromLocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  /**
     * Donate amount to project
     * @param project 
     */
  directDonate(project: any, flag: any, impact$?: any) {
    //this.app.spinner.show();
    let obj = this.makeCartValue(project);
    let popupMessage;
    let cartItem;
    if (this.isOverCartLimit()) {
      let cartLimit = localStorage.getItem('cart_limit') || 30;
      popupMessage = "You can add maximum " + cartLimit + " items in your cart box.";
      this.setCartCount();
      const modalRef = this.modalService.open(PopupComponent, { windowClass: 'message-modal', centered: true });
      modalRef.componentInstance.message = popupMessage;
    } else {
      if (!this.isItemExist(obj, 'cart_box', flag, impact$)) {
        cartItem = this.prepairItem(obj, flag, impact$);
        let url = (this.auth.isLoggedIn) ? Constant.ENDPOINT['addItemByUser'] : Constant.ENDPOINT['addItem'];
        this.service.postData(url, { items: cartItem, extra: [] }).then(
          res => {
            //this.app.spinner.hide();
            console.log('cart item', res);
            this.addItemToBox(cartItem, 'cart_box');
            this.setCartCount();
            // redirect to donate page
            this.router.navigateByUrl('/donation/box');
          },
          err => {
            //this.app.spinner.hide();
            console.log('cart item error', err);
            popupMessage = "Item is not added in your cart..please try again";
            const modalRef = this.modalService.open(PopupComponent, { windowClass: 'message-modal', centered: true });
            modalRef.componentInstance.message = popupMessage;
          }
        );
      } else {
        // redirect to donate page
        this.router.navigateByUrl('/donation/box');
      }
    }
  }

  /**
     * Add project into cart
     * @param project 
     */
  addToBox(project: any, flag: any, impact$?: any) {
    //this.app.spinner.show();
    //this.removeItemFromLocalStorage('cart_box');
    let obj = this.makeCartValue(project);
    let popupMessage;
    let cartItem;
    if (this.isOverCartLimit()) {
      let cartLimit = localStorage.getItem('cart_limit') || 30;
      popupMessage = "You can add maximum " + cartLimit + " items in your cart box.";
      const modalRef = this.modalService.open(PopupComponent, { windowClass: 'message-modal', centered: true });
      modalRef.componentInstance.message = popupMessage;
    } else {
      // if project hasn't already added to box
      if (!this.isItemExist(obj, 'cart_box', flag, impact$)) {
        cartItem = this.prepairItem(obj, flag, impact$);
        console.log('befor api', cartItem);
        popupMessage = 'Your donation has been added to the box.';
        let url = (this.auth.isLoggedIn) ? Constant.ENDPOINT['addItemByUser'] : Constant.ENDPOINT['addItem'];
        this.service.postData(url, { items: cartItem, extra: [] }).then(
          res => {
            //this.app.spinner.hide();
            console.log('cart item', res);
            this.addItemToBox(cartItem, 'cart_box');
            this.setCartCount();
            const modalRef = this.modalService.open(PopupComponent, { windowClass: 'message-modal', centered: true });
            modalRef.componentInstance.message = popupMessage;
          },
          err => {
            // this.app.spinner.hide();
            console.log('cart item error', err);
            popupMessage = "Item is not added in your cart..please try again";
            const modalRef = this.modalService.open(PopupComponent, { windowClass: 'message-modal', centered: true });
            modalRef.componentInstance.message = popupMessage;
          }
        );
      } else {
        let name = '';//(typeof impact$ != 'undefined') ? impact$.title : ((typeof obj!= 'undefined' && typeof obj.name!= 'undefined') ? obj.name:"");
        popupMessage = name + " is already added in the box.";
        const modalRef = this.modalService.open(PopupComponent, { windowClass: 'message-modal', centered: true });
        modalRef.componentInstance.message = popupMessage;
      }

    }

  }

  /**
   * Create cart object
   * @param donationItem 
   */
  makeCartValue(donationItem: any) {
    let obj = {};
    obj['id'] = donationItem.id;
    obj['user_id'] = donationItem.user_id;
    if(donationItem.is_project == 1){
      obj['name'] = donationItem.name;
      obj['image'] = donationItem.image;
      obj['charity_id'] = (typeof donationItem.charity_id != 'undefined' ? donationItem.charity_id : '');
      obj['total_collected'] = donationItem.total_collected;
    }else if(donationItem.is_campaign == 1){
      if(donationItem.is_impact ==1){
        obj['name'] = donationItem.title;
        obj['image'] = donationItem.imageURL;
        obj['charity_id'] = 0;
        obj['is_campaign'] = 1;
        obj['is_impact'] = 1;
        obj['campaign_id'] = donationItem.campaign_id;
        obj['total_collected'] = donationItem.amount;
      }else{
        obj['name'] = donationItem.title;
        obj['image'] = donationItem.imageURL;
        obj['charity_id'] = 0;
        obj['is_campaign'] = 1;
        obj['total_collected'] = donationItem.raised_amount;
      }      
    }
    return obj;
  }

  /**
   * Check cart limit
   */
  isOverCartLimit(): boolean {
    let cartLimit = localStorage.getItem('cart_limit') || 30;
    cartLimit = Number(cartLimit);
    if (this.getCartItemCount() >= cartLimit) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Get cart item count
   */
  getCartItemCount() {
    let cartItems = this.getBoxItems('cart_box');
    return cartItems.length;
  }

  /**
   * Set cart count on header
   */
  setCartCount() {
    let count = this.getCartItemCount();
    if (count > 0) {
      $('span.donation-label').show();
      $('span.donation-label').html(count);
    } else {
      $('span.donation-label').hide();
    }
  }

}
