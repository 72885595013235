import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, FormControl } from '@angular/forms';

function validateEmailFactory() {
  return (c: FormControl) => {
    let EMAIL_REGEXP = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5})$/i;
    if (c.value != "" && c.value != undefined) {
      return EMAIL_REGEXP.test(c.value) ? null : {
        validateEmail: {
          valid: false
        }
      };
    }
  };
}

@Directive({
  selector: '[validateEmail][formControl],[validateEmail][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => validateEmail), multi: true }
  ]
})

export class validateEmail {
  validator: Function;
  constructor() {
    this.validator = validateEmailFactory();
  }
  validate(c: FormControl) {
    return this.validator(c);
  }
}
