import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constant } from '../settings/constants';
import * as $ from 'jquery';
import { BehaviorSubject } from 'rxjs';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  public baseUrl: string = Constant.API_ENDPOINT;
  private fundDetailsSource = new BehaviorSubject<any>(null);
  fundDetails$ = this.fundDetailsSource.asObservable();

  constructor(
    private http: HttpClient) { }


  setFundDetails(fundRaisingObject: any) {
    this.fundDetailsSource.next(fundRaisingObject);
    console.log('fund data:', fundRaisingObject);
  }

  /**
   * Get data using Rest Api GET Method
   * @param string urlString Url End Point
   */
  getData(urlString: string) {
    let apiUrl = this.baseUrl + urlString;
    let promise = new Promise((resolve, reject) => {
      this.http.get(apiUrl).toPromise().then(
        res => { // Success
          resolve(res);
        }
      ).catch(err => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * Post data using Rest Api POST Method
   * @param string urlString Url End Point
   * @param object data
   */
  postData(urlString: string, data: object) {

    let apiUrl = this.baseUrl + urlString;
    let promise = new Promise((resolve, reject) => {
      this.http.post(apiUrl, data, httpOptions).toPromise().then(
        res => { // Success
          resolve(res);
        }
      ).catch(err => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * Post data & file using Rest Api POST Method
   * @param string urlString Url End Point
   * @param object data
   */
  postDataWithFile(urlString: string, data: object) {
    let apiUrl = this.baseUrl + urlString;
    let promise = new Promise((resolve, reject) => {
      this.http.post(apiUrl, data).toPromise().then(
        res => { // Success
          resolve(res);
        }
      ).catch(err => {
        reject(err);
      });
    });
    return promise;
  }
  /**
  * Post file using Rest Api POST Method
  * @param string urlString Url End Point
  * @param object data
  */
  postFile(urlString: string, data: object) {

    let apiUrl = this.baseUrl + urlString;
    let promise = new Promise((resolve, reject) => {
      this.http.post(apiUrl, data).toPromise().then(
        res => { // Success
          resolve(res);
        }
      ).catch(err => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * Post data using Rest Api PUT Method
   * @param string urlString Url End Point
   * @param object data
   */
  putData(urlString: string, data: object) {
    let apiUrl = this.baseUrl + urlString;
    let promise = new Promise((resolve, reject) => {
      this.http.put(apiUrl, data, httpOptions).toPromise().then(
        res => { // Success
          resolve(res);
        }
      ).catch(err => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * Delete data using Rest Api Delete Method
   * @param string urlString Url End Point
   */
  deleteData(urlString: string) {
    let apiUrl = this.baseUrl + urlString;
    let promise = new Promise((resolve, reject) => {
      this.http.delete(apiUrl).toPromise().then(
        res => { // Success
          resolve(res);
        }
      ).catch(err => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * Get new alias if already exist to server
   * @param string alias
   * @param string table
   * @param boolea mode
   */
  getAlias(alias: string, table: string, id: number) {
    let promise = new Promise((resolve, reject) => {
      this.postData(Constant.ENDPOINT['getAlias'], { alias: alias, table_name: table, id: id }).then(
        res => {
          if (res['code'] == 401) {
            reject('empty');
          } else if (res['code'] == 201) {
            reject('exists');
          } else {
            resolve(res['data']);
          }
        },
        err => {
          reject(err);
        }
      );
    });
    return promise
  }

  setEqualHeight() {
    var footerH = $("footer").outerHeight();
    $("footer").css({ "margin-top": -footerH });
    $(".wrapper").css({ "padding-bottom": footerH });
    $.fn.extend({
      equalHeights: function() {
        var top = 0;
        var row = [];
        var classname = ('equalHeights' + Math.random()).replace('.', '');
        $(this).each(function() {
          var thistop = $(this).offset().top;
          if (thistop > top) {
            $('.' + classname).removeClass(classname);
            top = thistop;
          }
          $(this).addClass(classname);
          $(this).height('auto');
          var h = (Math.max.apply(null, $('.' + classname).map(function() {
            return $(this).height();
          }).get()));
          $('.' + classname).height(h);
        }).removeClass(classname);
      }
    });
    $(".benefits-section  .benefit-content h4").equalHeights();
    // $(".project-block h4 a").equalHeights();
    // $(".project-block h4 span").equalHeights();
    $('.manage-list-item .project-content').equalHeights();
    // $('.project-content p').equalHeights();
    $(".project-detail-donate .donation-item p").equalHeights();
    $(".charity-dashboard-bot-box .box-content").equalHeights();
  }

  number2words(number){
    const first = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
    const tens = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];
    const mad = ['', 'thousand', 'million', 'billion', 'trillion'];
    let word = '';

    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number%(100*Math.pow(1000,i));
      if (Math.floor(tempNumber/Math.pow(1000,i)) !== 0) {
        if (Math.floor(tempNumber/Math.pow(1000,i)) < 20) {
          word = first[Math.floor(tempNumber/Math.pow(1000,i))] + mad[i] + ' ' + word;
        } else {
          word = tens[Math.floor(tempNumber/(10*Math.pow(1000,i)))] + '-' + first[Math.floor(tempNumber/Math.pow(1000,i))%10] + mad[i] + ' ' + word;
        }
      }

      tempNumber = number%(Math.pow(1000,i+1));
      if (Math.floor(tempNumber/(100*Math.pow(1000,i))) !== 0) word = first[Math.floor(tempNumber/(100*Math.pow(1000,i)))] + 'hunderd ' + word;
    }
      return word;
  }
}
