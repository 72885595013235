import { Component, OnInit } from '@angular/core';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GeneralService } from './services/general.service';
import { DonateService } from './services/donate.service';
import { Constant } from './settings/constants';
import * as $ from 'jquery';

declare var ga: Function;
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	providers: [GeneralService]
})

export class AppComponent implements OnInit {
	spinkit = Spinkit;
	device_type: number = null;

	constructor(
		private router: Router,
		public spinner: SpinnerVisibilityService,
		private deviceService: DeviceDetectorService,
		private service: GeneralService,
		private donate: DonateService
	) {
		router.events.subscribe((event: RouterEvent) => {
			this._navigationInterceptor(event);
		})
	}

	_navigationInterceptor(event: RouterEvent): void {
		if (event instanceof NavigationStart && !event.url.includes('admin')) {
			this.spinner.show();
		}

		// if (event instanceof NavigationEnd) {
		// 	this.spinner.hide();
		// }
		if (event instanceof NavigationEnd) {
			this.donate.setCartCount();
			ga('set', 'page', event.urlAfterRedirects);
			ga('send', 'pageview');
		}

		if (event instanceof NavigationCancel) {
			this.spinner.hide();
		}

		if (event instanceof NavigationError) {
			this.spinner.hide();
		}
	}

	ngOnInit() {
		if (!location.href.includes('admin')) {
			this.storeUserDeviceInfo();

		}
	}

	storeUserDeviceInfo() {
		if (this.deviceService.isDesktop()) {
			this.device_type = 0;
		} else if (this.deviceService.isMobile()) {
			this.device_type = 1;
		} else if (this.deviceService.isTablet()) {
			this.device_type = 2;
		}
		this.service.postData(Constant.ENDPOINT['add-user-device-info'], { device_type: this.device_type }).then(
			res => {
				console.log('device res', res);
				if (res['data']) {
					localStorage.setItem('cart_limit', res['data'].cart_limit);
					localStorage.setItem('ip_address', res['data'].ip);
				}
			},
			err => {
				console.log('device info error', err)
			}
		);
	}

}
